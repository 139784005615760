import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

$(".card-carousel", document).each((_index, carousel) => {
  let $carousel = $(carousel);
  let outer = $carousel.find(".carousel-outer")[0];
  let inner = $carousel.find(".carousel-inner")[0];
  let $cards = $carousel.find(".carousel-inner > article, .carousel-inner > div");
  let $controller = $carousel.find("nav.controller");

  $cards.first().addClass("active");

  revealControls();
  $(window).on("orientationchange, resize", revealControls);

  $controller.find("button").on("click", (evt) => {
    let change = evt.target.classList.contains("right") ? 1 : -1;

    let $activeCard = $cards.filter(".active");
    let newIndex = $activeCard.index() + change;

    if (change === 1 && (newIndex >= $cards.length || (outer.scrollLeft + outer.offsetWidth) >= inner.offsetWidth)) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = $cards.length - 1;
    }

    let $newCard = $cards.eq(newIndex);

    $activeCard.removeClass("active");
    $newCard.addClass("active");

    let xpos = $newCard[0].offsetLeft;

    outer.scroll({
      left: xpos,
      behavior: "smooth"
    });
  });

  function revealControls() {
    if (inner.offsetWidth > outer.offsetWidth) {
      $controller.removeClass("invisible");
    } else {
      $controller.addClass("invisible");
    }
  }
});
