import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

$(".document-selector button").on("click", (evt) => {
  let $button = $(evt.target);
  let $container = $(".document-section");
  let newClass = $button.attr("data-view");

  $button.siblings().removeClass("active");
  $container.removeClass("document-list").removeClass("document-cards");

  $button.addClass("active");
  $container.addClass(newClass);
});

function filterSelects(evt) {
  let $container = $(".documents-body");
  let $items = $container.children();

  $container.find(".no-results").remove();
  $items.removeClass("hidden").removeClass("odd");

  $(".document-filters select.filter").each((idx, select) => {
    let field = select.name;
    let term = select.options[select.selectedIndex].text;

    if (select.value) {
      $items.not(".hidden").each((idx, el) => {
        let value = el.getAttribute(`data-${field}`);
        let regex = new RegExp(`(?:^|,)${term}(?:,|$)`, "g");

        if (!regex.test(value)) {
          $(el).addClass("hidden");
        }
      });
    }

    filterSearch();
  });
};

function filterSearch(evt) {
  let $term = $(".search-term");
  let $container = $(".documents-body");
  let $items = $container.children();

  let term = $term.val().toLowerCase();

  if (term) {
    $items.not(".hidden").each((idx, el) => {
      let $item = $(el);
      if (
        ($item.find("[itemprop=\"Name\"").text().toLowerCase().indexOf(term) < 0)
        &&
        ($item.attr("data-Description").toLowerCase().indexOf(term) < 0)
      ) {
        $item.addClass("hidden");
      }
    });
  }

  filterCleanup();
}

function filterCleanup() {
  let $container = $(".documents-body");
  let $items = $container.children();

  if ($items.not(".hidden").length < 1) {
    $container.prepend("<div class=\"no-results p-4\">No Results</div>");
  } else {
    $items.not(".hidden").each((idx, el) => {
      if (idx % 2 != 1) {
        $(el).addClass("odd");
      }
    });
  }
}

// Run filterCleanup on load just so odds can be set
$(".document-filters").each(filterCleanup);

$(".document-filters select.filter").on("change", filterSelects);

$(".document-filters .search-term").on("search", filterSelects);

$(".document-filters button.search").on("click", filterSelects);
