window.initMap = function() {
  const map = new google.maps.Map(document.querySelector(".locations-map"), {
    center: { lat: 29.9306658, lng: -95.4326346 },
    zoom: 4,
  });

  mapLocations(map);
};

if (document.querySelector(".locations-map")) {
  // Create the script tag, set the appropriate attributes
  const script = document.createElement("script");
  script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAng0CAmoCkg_ysvGii_NyeGE_ZaCmAUvs&callback=initMap";
  script.async = true;

  // Append the 'script' element to 'head'
  document.head.appendChild(script);
}

function mapLocations(map) {
  const bounds = new google.maps.LatLngBounds();
  const infowindow = new google.maps.InfoWindow();

  document.querySelectorAll("[data-latitude][data-longitude]").forEach((el) => {
    const loc = {
      lat: el.getAttribute("data-latitude"),
      lng: el.getAttribute("data-longitude"),
      name: el.querySelector("[itemprop=\"location-name\"]")?.textContent,
      address: el.querySelector("[itemprop=\"address\"]")?.textContent,
      city: el.querySelector("[itemprop=\"city\"]")?.textContent,
      state: el.querySelector("[itemprop=\"state\"]")?.textContent,
      zip: el.querySelector("[itemprop=\"zipcode\"]")?.textContent,
      phone: el.querySelector("[itemprop=\"phone\"]")?.textContent,
      fax: el.querySelector("[itemprop=\"fax\"]")?.textContent,
      email: el.querySelector("[itemprop=\"email\"")?.textContent.trim()
    };

    // @ts-ignore
    const marker = new google.maps.Marker({
      // @ts-ignore
      position: new google.maps.LatLng(loc.lat, loc.lng),
      map,
      title: loc.name
    });

    bounds.extend(marker.position);

    const contentString = `
      <div class="font-primary text--ms1">
        <p class="font-extrabold">${loc.name}</p>
        <p class="mt-2">${loc.address}<br />
          ${loc.city}, ${loc.state} ${loc.zip}</p>
        <p class="mt-2 icon-phone"><a href="tel:${loc.phone}">${loc.phone}</a></p>
      </div>
    `;

    marker.addListener("click", () => {
      infowindow.close();
      infowindow.setContent(contentString);
      infowindow.open({
        anchor: marker,
        map: map,
        shouldFocus: false
      });
    });

  });
  map.fitBounds(bounds);
}
