import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";
import dialogPolyfill from "../../../node_modules/dialog-polyfill/dist/dialog-polyfill.esm.js";

$("dialog", document).each((_index, dialog) => {
  dialogPolyfill.registerDialog(dialog);
});

$("a[rel*=\"lightbox\"][href*=\".html\"]").on("click", (evt) => {
  evt.preventDefault();

  let target = evt.target;
  let $dialog = $("dialog#lightbox");
  let $close = $dialog.find("button.modal-close");

  let lightbox = $dialog.get(0);
  let href = target.href;
  let $iframe;

  let offset = (document.documentElement.clientWidth < 768) ? 0 : 100;
  let width = target.getAttribute("data-width");
  let height = target.getAttribute("data-height");

  width = width || document.documentElement.clientWidth - offset;
  height = height || document.documentElement.clientHeight - offset;

  $iframe = $(`<iframe src="${href}" frameborder="0" width="${width}" height="${height}" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay"></iframe>`);
  $iframe.appendTo($dialog);

  lightbox.showModal();

  setTimeout(() => {
    $dialog.addClass("shown");
  }, 50);

  function closeDialog(evt) {
    if (evt.target === $close.get(0) || evt.target == lightbox) {
      evt.preventDefault();
      $dialog.removeClass("shown");
      lightbox.removeEventListener("click", closeDialog);
      $close.off("click");
      setTimeout(() => {
        lightbox.close();
      }, 750);
    }
  }

  lightbox.addEventListener("click", closeDialog);
  $close.on("click", closeDialog);

  $dialog.on("close", event => {
    $iframe.detach();
    $dialog.off("close");
  });
});
