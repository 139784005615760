import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

$(".clickable").on("click", function cardClick(evt) {
  var card = $(this);

  var showingHistory = card.find(".SAMChunkForm").find(".SAMChunkHistory").length > 0;

  if (!this.classList.contains("SAMEditing") && !showingHistory) {

    let link = this.querySelector("a");

    if (evt.target !== link) {
      evt.preventDefault();
      link.click();
    }
  }
});
