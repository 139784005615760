import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

$(".marquee-home .right-image").each((idx, container) => {
  let $container = $(container);
  let $children = $container.children();

  $children.not(".active").find("img").attr("loading", "lazy");

  window.homeMarqueeInterval = setInterval(() => {
    let $lastActive = $children.filter(".active").last();
    let $nextSlide = $lastActive.next();
    if ($nextSlide.index() < 0) {
      $children.removeClass("active");
      $nextSlide = $children.first();
    }
    $nextSlide.addClass("active");
  }, 5000);
});
