import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

window.addEventListener("load", () => {
  const stickyElm = document.querySelector(".page-header");
  const rootMarginTop = (parseInt(window.getComputedStyle(stickyElm).getPropertyValue("top")) * -1) - 1;

  const observer = new IntersectionObserver(
    ([e]) => {
      e.target.classList.toggle("stuck", e.intersectionRatio < 1);
    },
    {
      threshold: [1],
      rootMargin: `${rootMarginTop}px 0px 0px 0px`
    }
  );

  observer.observe(stickyElm);
});

$("button.mm").on("click", (evt) => {
  const $button = $(evt.target);
  if (!$button.hasClass("mm-close")) {
    $(".primary-bar").addClass("open");
    $(".primary-nav").removeClass("hidden");
    $("body").addClass("mm-fixed");
    $button.removeClass("button").addClass("mm-close").html("<span class=\"sr-only\">close</span>");
  } else {
    $(".primary-bar").removeClass("open");
    $(".primary-nav").addClass("hidden");
    $("body").removeClass("mm-fixed");
    $button.removeClass("mm-close").addClass("button").html("Menu");
  }
});

$(".primary-nav a[aria-haspopup=\"true\"]").on("click", (evt) => {
  let $link = $(evt.target);
  let mmButton = document.querySelector("button.mm").offsetParent;

  if ($link.attr("aria-haspopup") === "true" && mmButton) {
    evt.preventDefault();
    let open = $link.attr("aria-expanded") === "true";
    $(".primary-nav [aria-haspopup]").attr("aria-expanded", "false");
    if (open) {
      $link.attr("aria-expanded", "false");
    } else {
      $link.attr("aria-expanded", "true");
    }
  }
});
