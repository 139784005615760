import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

// @ts-ignore - Inject SVGs
SVGInjector(document.querySelectorAll(".markets-circle figure img"));

$(".circle-section", document).each((_index, section) => {
  let $section = $(section);
  let $circles = $section.find(".big-circle").find("div,figure");
  let $figures = $circles.filter("figure");
  let $captions = $section.find(".markets-caption > div");
  let $controller = $section.find("nav.controller");
  let arms = 0;

  $section.removeClass("circles-2").addClass("circles-" + ($circles.length - 1));

  if ($section.hasClass("arms")) {
    let armCount = $figures.length;
    let start = (armCount === 2 || armCount === 3 || armCount === 6) ? -90 : 0;
    let shape = armCount === 3 ? 270 : 360;
    $circles.filter("figure").each((idx) => {
      let $arm = $("<span class=\"arm\"></span>");
      let degree = start + (shape / $figures.length) * idx;
      $arm.css("transform", `rotate(${degree}deg)`);
      $circles.first().before($arm);
      arms++;
    });
  }

  $circles.first().addClass("active");
  $captions.first().addClass("active");

  $controller.find("button").on("click", (evt) => {
    let change = evt.target.classList.contains("right") ? 1 : -1;
    let $activeCircle = $circles.filter(".active");
    let newIndex = ($activeCircle.index() - arms) + change;

    if (newIndex >= $circles.length) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = ($circles.length - 1);
    }

    $activeCircle.removeClass("active");
    $captions.filter(".active").removeClass("active");
    $circles.eq(newIndex).addClass("active");
    $captions.eq(newIndex).addClass("active");
  });

  $circles.on("click", function() {
    let newIndex = $(this).index() - arms;
    $circles.removeClass("active");
    $captions.removeClass("active");
    $circles.eq(newIndex).addClass("active");
    $captions.eq(newIndex).addClass("active");
  });
});
